export const AppRoutes = {
  MAIN: '/',
  GAME: '/game',
  WEBSITE: '/website',
  NONPROFIT: '/nonprofit',
  NEWSPAPER: '/newspaper',
  // CONTACT: '/contact',
  MOBILE: '/mobile',
  BLOGALLARTICLES: '/blog-all',
  // Use a query path to go to each article dynamically and not lost the images path by doing this:
  EXPLORINGOFFERWALL: '/article?name=exploringofferwall',
  WHATISANOFFERWALL: '/article?name=whatisanofferwall',
  MAXIMIZINGOFFERWALL: '/article?name=maximizingofferwall',
  HOWITWORKS: '/howitworks',
  ARTICLE: '/article',
  ABOUTUS: '/aboutus',
  IMPRINT: '/imprint',
  DATAPRIVACY: '/dataprivacy',
  TOC: '/toc',
};
